.facetbox {
    border-right: 1px solid #f0f0f0;
}

.box {
    height: 100%;
}

.listitem {
    padding-left: 36px !important;
}

.filterlist {
    list-style: none;
}

.chip {
    margin: 0.25em;
}